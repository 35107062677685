import { ComponentPropsWithoutRef } from 'react';
import { ContentfulRichText } from '@/components/Contentful/common/ContentfulRichText';
import { useEntryField } from '../App.hooks';
type BaseProps = {
  fieldPath?: string;
};
type Props = BaseProps & Omit<ComponentPropsWithoutRef<typeof ContentfulRichText>, 'field'>;
export function AppRichText({
  fieldPath,
  ...rest
}: Props) {
  const field = useEntryField({
    fieldPath
  });
  return <ContentfulRichText {...rest} field={field} data-sentry-element="ContentfulRichText" data-sentry-component="AppRichText" data-sentry-source-file="AppRichText.tsx" />;
}